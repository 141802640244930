<template>
  <div>
    <el-form ref="form" label-width="80px">
      <p>
        <el-input v-model="mobile" class="login-input" placeholder="手机号" suffix-icon="el-icon-user" />
      </p>
      <p>

        <el-input v-model="password" class="login-input" placeholder="请输入密码" show-password
          @keyup.enter.native="login" />
      </p>
      <div v-if="login_btn" @click="login" class="login_btn_ture">登录</div>

      <div v-else class="login_btn">登录</div>
    </el-form>

  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: '',
  data() {
    return {
      mobile: "",
      password: "",
      login_btn: false,
    };
  },
  computed: {
    changeData() {
      const { mobile, password } = this;
      return {
        mobile,
        password,
      };
    },
  },
  watch: {
    changeData() {
      if (this.mobile && this.password) {
        this.login_btn = true;
      } else {
        this.login_btn = false;
      }
    },
  },
  methods: {
    ...mapMutations(["setInfo"]),
    // 登录
    login() {
      if (!this.VerifyPhone()) {
        return;
      }
      this.$fecth
        .post("user/login", {
          mobile: this.mobile,
          password: this.password,
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("TOKEN", res.token);
            this.$notify({
              showClose: true,
              message: "恭喜你，登录成功！",
              type: "success",
            });
            localStorage.setItem("UserInfo", JSON.stringify(res));
            this.setInfo(res);
            //store 添加
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }
        });
    },
    VerifyPhone() {
      let reg = /^1[0-9]{10}$/;
      if (
        this.mobile == "" ||
        this.mobile.length <= 10 ||
        !reg.test(this.mobile)
      ) {
        this.$message("手机号格式错误");
        return;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang='less'>
.login-input {
  margin-bottom: 20px;

}

.login_btn {
  background: #dddddd;
  line-height: 40px;
  color: #666666;
  text-align: center;
  letter-spacing: 3px;
  font-size: 16px;
  border-radius: 4px;
}

.login_btn_ture {
  background: #12a0f3;
  line-height: 40px;
  color: white;
  text-align: center;
  letter-spacing: 3px;
  font-size: 16px;
  border-radius: 4px;
}
</style>
