<template>
  <div>
    <el-form ref="form" label-width="80px">
      <p>
        <el-input v-model="mobile" class="login-input" placeholder="手机号">
          <template slot="prepend">+86</template>
        </el-input>
      </p>
      <p style="display: flex; justify-content: space-between">
        <el-input v-model="code" style="margin-right: 10px" class="login-input code" placeholder="请输入验证码"
          suffix-icon="el-icon-warning-outline" />
        <el-button class="getCode" type="success" @click="getCode">{{
          btn
        }}</el-button>
      </p>
      <p>
        <el-input v-model="password" class="login-input" placeholder="密码" show-password>
        </el-input>
      </p>
      <p>
        <el-input v-model="passwordtwo" class="login-input" placeholder="再次输入密码" show-password>
        </el-input>
      </p>
      <div @click="reg" class="login_btn_ture">注册</div>
    </el-form>
    <el-dialog :show-close="false" :visible.sync="dialogVisibles" class="dia_msg" style="top:244px;right:18px"
      width="380px">
      <div class="login-tip">
        <div class="title">
          <img class="img" src="@/assets/img/login/icon_1.png" alt="">
          <span>提醒</span>
        </div>
        <div class="msg">{{ msg }}</div>
        <div class="btn">
          <el-button size="mini" round @click="dialogVisibles = false; act = 1">马上登录</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>  

<script>
import { mapMutations } from "vuex";
export default {
  name: '',
  data() {
    return {
      mobile: "",
      password: "",
      passwordtwo: '',
      btn: "获取验证码",
      time: 60,
      timer: null,
      code: "",
      dialogVisibles: false,
      msg: ''
    };
  },


  methods: {
    ...mapMutations(["setInfo"]),
    // 获取验证码
    getCode() {
      if (!this.VerifyPhone()) {
        return;
      }
      if (this.time === 60) {
        this.$fecth
          .post("user/sendPhoneCode", {
            mobile: this.mobile,
          })
          .then(() => {
            this.$message({
              showClose: true,
              message: "发送成功！",
              type: "success",
            });
            this.timer = setInterval(() => {
              this.time--;
              this.btn = this.time + "s后重新发送";
              if (this.time < 1) {
                clearInterval(this.timer);
                this.timer = null;
                this.time = 60;
                this.btn = "获取验证码";
              }
            }, 1000);
          });
      }
    },
    //普通注册
    reg() {
      if (!this.VerifyPhone()) {
        return;
      }
      if (!this.code) {
        this.$message("请输入验证码");
        return;
      }
      if (!this.password) {
        this.$message("请输入密码");
        return;
      }
      if (this.password != this.passwordtwo) {
        this.$message("两次密码不同，请重新输入");
        return;
      }
      this.$fecth
        .post("user/register", {
          mobile: this.mobile,
          password: this.password,
          code: this.code,
        })
        .then((res) => {
          if (res) {
            if (res.code == 301) {
              this.dialogVisibles = true;
              this.state = 1;
              this.msg = res.msg;
              return false;
            }
            this.$notify({
              showClose: true,
              message: "恭喜你，注册成功！",
              type: "success",
            });
            this.setInfo(res);
            localStorage.setItem("UserInfo", JSON.stringify(res));
            localStorage.setItem("TOKEN", res.token);
            this.$router.push("/information");
          }
        });
    },
    VerifyPhone() {
      let reg = /^1[0-9]{10}$/;
      if (
        this.mobile == "" ||
        this.mobile.length <= 10 ||
        !reg.test(this.mobile)
      ) {
        this.$message("手机号格式错误");
        return;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang='less'>
.login-input {
  margin-bottom: 20px;
}

.login_btn_ture {
  background: #12a0f3;
  line-height: 40px;
  color: white;
  text-align: center;
  letter-spacing: 3px;
  font-size: 16px;
  border-radius: 4px;
}

.getCode {
  height: 40px;
}

.login-tip {
  position: relative;

  .title {
    text-align: center;
  }

  .msg {
    text-align: center;
    margin: 20px 0;
  }

  .btn {
    text-align: center;
  }
}
</style>
