<template>
  <div class="login">
    <div class="content">
      <div class="form" v-if="nav == 1">
        <div class="title">
          <span :class="{ act: act == 1 }" @click="act = 1">登录</span>
          <span :class="{ act: act == 2 }" @click="act = 2">注册</span>
        </div>

        <div v-if="act == 1">
          <login_form></login_form>
          <div class="bottom">
            <div>
              <router-link to="Register">会员卡激活</router-link>
            </div>
            <div @click="nav = 2">忘记密码</div>
          </div>
        </div>
        <div v-if="act == 2">
          <login_register></login_register>
        </div>
      </div>

      <div class="form" v-if="nav == 2">
        <reset_password></reset_password>
      </div>
    </div>
  </div>
</template>

<script>
import login_form from './components/LoginForm.vue'
import login_register from './components/LoginRegister.vue'
import reset_password from './components/ResetPassword.vue'
export default {
  name: "Login",
  components: {
    login_form, login_register, reset_password
  },
  data() {
    return {
      nav: 1,
      act: 1,
    };
  },
};
</script>

<style lang="less" scoped>
.login {
  background: url("../../assets/img/login/login_bg.jpg");
  min-height: 770px;
  overflow: hidden;

  .content {
    width: 1200px;
    margin: 0 auto;
  }

  .form {
    position: relative;
    width: 314px;
    margin: 160px auto 0;
    min-height: 290px;
    background: white;
    padding: 42px 33px 36px;
    border-radius: 7px;

    .title {
      border-bottom: 1px solid #b3b3b3;
      margin-bottom: 30px;
      padding-bottom: 7px;
      font-size: 18px;
      color: #b3b3b3;

      span {
        padding-bottom: 8px;
        margin-right: 15px;
      }
    }

    .act {
      color: black;
      position: relative;
      // top: 1px;
      padding-bottom: 1px;
      border-bottom: 2px solid #2771fa;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      font-size: 16px;
      color: #b3b3b3;

      a {
        color: #b3b3b3;
      }
    }
  }
}
</style>