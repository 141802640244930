<template>
  <div>
    <div class="title">
      找回密码
    </div>
    <p>
      <el-input v-model="mobile" class="login-input" placeholder="手机号" suffix-icon="el-icon-user" />
    </p>
    <p style="display: flex; justify-content: space-between">
      <el-input v-model="code" style="margin-right: 10px" class="login-input code" placeholder="请输入验证码"
        suffix-icon="el-icon-warning-outline" />
      <el-button class="getCode" type="success" @click="getCode">{{
          btn
      }}</el-button>
    </p>
    <p style="position: relative">
      <el-input v-model="password" class="login-input" show-password placeholder="请输入新密码" />
    </p>
    <div class="login_btn_ture" @click="getPwd">确认</div>
    <div class="bottom">
      <div @click="backLogin()">返回登录</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      mobile: "",
      password: "",
      btn: "获取验证码",
      time: 60,
      timer: null,
      code: "",
    };
  },


  methods: {
    // 获取验证码
    getCode() {
      if (!this.VerifyPhone()) {
        return;
      }
      if (this.time === 60) {
        this.$fecth
          .post("user/sendPhoneCode", {
            mobile: this.mobile,
          })
          .then(() => {
            this.$message({
              showClose: true,
              message: "发送成功！",
              type: "success",
            });
            this.timer = setInterval(() => {
              this.time--;
              this.btn = this.time + "s后重新发送";
              if (this.time < 1) {
                clearInterval(this.timer);
                this.timer = null;
                this.time = 60;
                this.btn = "获取验证码";
              }
            }, 1000);
          });
      }
    },
    VerifyPhone() {
      let reg = /^1[0-9]{10}$/;
      if (
        this.mobile == "" ||
        this.mobile.length <= 10 ||
        !reg.test(this.mobile)
      ) {
        this.$message("手机号格式错误");
        return;
      } else {
        return true;
      }
    },
    //修改密码
    getPwd() {
      if (!this.VerifyPhone()) {
        return;
      }
      if (!this.password) {
        this.$message("请输入密码");
        return;
      }
      if (!this.code) {
        this.$message("请输入验证码");
        return;
      }
      this.$fecth
        .post("user/resetpwd", {
          mobile: this.mobile,
          password: this.password,
          code: this.code,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              showClose: true,
              message: "密码设置成功！",
              type: "success",
            });
            this.backLogin()
          }
        });
    },
    backLogin() {
      this.$parent.$data.nav = 1;
    }
  },

};
</script>


<style scoped lang='less'>
.title {
  color: #b3b3b3;
  font-size: 18px;
  vertical-align: middle;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;

}

.login-input {
  margin-bottom: 20px;
}

.login_btn_ture {
  background: #12a0f3;
  line-height: 40px;
  color: white;
  text-align: center;
  letter-spacing: 3px;
  font-size: 16px;
  border-radius: 4px;
}

.getCode {
  height: 40px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  font-size: 16px;
  color: #b3b3b3;

  a {
    color: #b3b3b3;
  }
}
</style>
